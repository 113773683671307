import { FedLeg, LegislatorTypes, StateLeg } from "APTAPackage";

const isAlaskaTypeArr = [
  LegislatorTypes.STATE_SUM,
  LegislatorTypes.SENATE,
  LegislatorTypes.HOUSE,
];

type T_res = { isAlaskaZoom: boolean; isAlaskaPadding: boolean };

export function isAlaska(member: FedLeg | StateLeg): T_res {
  const type = member?.identityInfo?.type;
  const state = member?.identityInfo?.stateAbbrev;

  if (state != "AK" && state != "ak")
    return { isAlaskaPadding: false, isAlaskaZoom: false };
  else if (!isAlaskaTypeArr.includes(type)) {
      return { isAlaskaPadding: false, isAlaskaZoom: false };
  } 
    return { isAlaskaPadding: true, isAlaskaZoom: true };

}
