"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.layerIdObj = exports.layerObj = void 0;
// export const layerObj_old: T_layerObj = {
//   // APTAMembersDBE: {
//   //   key: "APTAMembersDBE",
//   //   name: "APTA Members DBE",
//   //   layerId: "bc6b5938a137418f93325b0f7533f8cf",
//   //   visible: false,
//   // },
//   APTAMembersDBE: {
//     key: "APTAMembersDBE",
//     name: "APTA Members DBE",
//     layerId: "ff987e7f30ce40baa4608c49a25f92b5",
//     visible: false,
//   },
//   APTAMembers: {
//     key: "APTAMembers",
//     name: "APTA Members",
//     layerId: "1103513fbc3c4a61a9e8545e36a676f7",
//     visible: false,
//   },
//   RuralProviders: {
//     key: "RuralProviders",
//     name: "Rural Public Transit Providers",
//     layerId: "873856ab3dfd46709ece0324d1b9756e",
//     visible: false,
//   },
//   BusManu: {
//     key: "BusManu",
//     name: "Bus Manufacturers",
//     layerId: "3e3e351a8bbd4a888b4421bb7156b0d2",
//     visible: false,
//   },
//   RailManu: {
//     key: "RailManu",
//     name: "Rail Manufacturers",
//     layerId: "8b611df1549d4731b5a1c9445496ffb5",
//     visible: false,
//   },
//   BusStationAreas: {
//     key: "BusStationAreas",
//     name: "Bus Station Areas",
//     layerId: "08539e584365402b91fbafa2ad1d7478",
//     visible: false,
//   },
//   AmtrakStations: {
//     key: "AmtrakStations",
//     name: "Amtrak Stations",
//     layerId: "05f53bd354c74667a83c032aa47caf3f",
//     visible: false,
//   },
//   AmtrakRoutes: {
//     key: "AmtrakRoutes",
//     name: "Amtrak Routes",
//     layerId: "8729a58d4b864a01a1c8786682ea3396",
//     visible: false,
//   },
//   RailStations: {
//     key: "RailStations",
//     name: "Rail Stations",
//     layerId: "8fb9b926ee2d45f5bb1cef9e17febb7a",
//     visible: false,
//   },
//   RailLines: {
//     key: "RailLines",
//     name: "Rail Lines",
//     layerId: "e9201298d352409c9e441d2909fda5aa",
//     visible: false,
//   },
//   TransitAgency: {
//     key: "TransitAgency",
//     name: "Transit Agency",
//     layerId: "fee8734e57894ac4a4cd2891f52f8c1d",
//     visible: false,
//   },
//   TransitAgencyServiceArea: {
//     key: "TransitAgencyServiceArea",
//     name: "Transit Agency Service Area",
//     layerId: "b51b46dfdee346939d0a30d1a42106d0",
//     visible: false,
//   },
//   GrantData: {
//     key: "GrantData",
//     name: "Grant Data",
//     layerId: "74cdeab461314411b6b510b58c80561e",
//     visible: false,
//   },
//   PublicTransitOpportunityIndex: {
//     key: "PublicTransitOpportunityIndex",
//     name: "Public Transit Opportunity Index",
//     layerId: "1dc7bac1e4d34f19a0556d9ae048b443",
//     visible: false,
//   },
//   UrbanizedArea: {
//     key: "UrbanizedArea",
//     name: "Urbanized Area",
//     layerId: "5626ad6a735d4f798964afd0159c4ec5",
//     visible: false,
//   },
//   STATES: {
//     key: "STATES",
//     name: "States",
//     layerId: "1d843651380f417a98328b112ddcbb33",
//     visible: false,
//   },
//   HOUSE: {
//     key: "HOUSE",
//     name: "House",
//     layerId: "433601145e74eb0a3da5f015f11538d",
//     visible: false,
//   },
//   SLDL: {
//     key: "SLDL",
//     name: "SLDL",
//     layerId: "aec2536bfd9a4752a590eaba9450dc04",
//     visible: false,
//   },
//   SLDU: {
//     key: "SLDU",
//     name: "SLDU",
//     layerId: "8309bc28d894474ba53017d8536f93b8",
//     visible: false,
//   },
// };
exports.layerObj = {
    // APTAMembersDBE: {
    //   key: "APTAMembersDBE",
    //   name: "APTA Members DBE",
    //   layerId: "bc6b5938a137418f93325b0f7533f8cf",
    //   visible: false,
    // },
    APTAMembersDBE: {
        key: "APTAMembersDBE",
        name: "APTA Members DBE",
        layerId: "39addb17658642a29c1f302c37cc8c91",
        visible: false,
    },
    APTAMembers: {
        key: "APTAMembers",
        name: "APTA Members",
        layerId: "b0db8428a95749148d13e5a2cc126757",
        visible: false,
    },
    RuralProviders: {
        key: "RuralProviders",
        name: "Rural Public Transit Providers",
        layerId: "873856ab3dfd46709ece0324d1b9756e",
        visible: false,
    },
    BusManu: {
        key: "BusManu",
        name: "Bus Manufacturers",
        layerId: "4e3cd822032746f4b2aea97f953920bb",
        visible: false,
    },
    RailManu: {
        key: "RailManu",
        name: "Rail Manufacturers",
        layerId: "4d7f27b701d54d8189a287142e403f1c",
        visible: false,
    },
    BusStationAreas: {
        key: "BusStationAreas",
        name: "Bus Station Areas",
        layerId: "7d40e55519f0481c97c08715821634ff",
        visible: false,
    },
    AmtrakStations: {
        key: "AmtrakStations",
        name: "Amtrak Stations",
        layerId: "7922f325c0f74cb49c2b7c35efc27404",
        visible: false,
    },
    AmtrakRoutes: {
        key: "AmtrakRoutes",
        name: "Amtrak Routes",
        layerId: "882ec8117fcb42059a4b3c71f1a1af30",
        visible: false,
    },
    RailStations: {
        key: "RailStations",
        name: "Rail Stations",
        layerId: "eac94ea9c6d94b479b31b9b000b64a50",
        visible: false,
    },
    RailLines: {
        key: "RailLines",
        name: "Rail Lines",
        layerId: "320ff6dfb0414bc9b8d6cc2a651159e3",
        visible: false,
    },
    TransitAgency: {
        key: "TransitAgency",
        name: "Transit Agency",
        layerId: "2669bb0add9d41ebb17b8d40a4c0aaca",
        visible: false,
    },
    TransitAgencyServiceArea: {
        key: "TransitAgencyServiceArea",
        name: "Transit Agency Service Area",
        layerId: "b107750b74d04915ad0a38770419b10b",
        visible: false,
    },
    GrantData: {
        key: "GrantData",
        name: "Grant Data",
        layerId: "3bf8d73d690446d7a10199278fce110e",
        visible: false,
    },
    PublicTransitOpportunityIndex: {
        key: "PublicTransitOpportunityIndex",
        name: "Public Transit Opportunity Index",
        layerId: "1dc7bac1e4d34f19a0556d9ae048b443",
        visible: false,
    },
    UrbanizedArea: {
        key: "UrbanizedArea",
        name: "Urbanized Area",
        layerId: "5626ad6a735d4f798964afd0159c4ec5",
        visible: false,
    },
    STATES: {
        key: "STATES",
        name: "States",
        layerId: "234086b4e9464ec0b8aab08a82bb2c3e",
        visible: false,
    },
    HOUSE: {
        key: "HOUSE",
        name: "House",
        layerId: "ff1c8f810d2149bd8fbc7ddb9b901baa",
        visible: false,
    },
    SLDL: {
        key: "SLDL",
        name: "SLDL",
        layerId: "3126c7c26c7244fb8269cc6d1083055e",
        visible: false,
    },
    SLDU: {
        key: "SLDU",
        name: "SLDU",
        layerId: "99a916b7cfdb4014a4fb4969a809a4e2",
        visible: false,
    },
};
// export const layerIdObj_old: T_configObj = {
//   "1dc7bac1e4d34f19a0556d9ae048b443": {
//     key: "1dc7bac1e4d34f19a0556d9ae048b443",
//     name: "Public Transit Opportunity Index",
//     alias: "PublicTransitOpportunityIndex",
//     visible: false,
//   },
//   ff987e7f30ce40baa4608c49a25f92b5: {
//     key: "ff987e7f30ce40baa4608c49a25f92b5",
//     name: "APTA Members DBE",
//     alias: "APTAMembersDBE",
//     visible: false,
//   },
//   // bc6b5938a137418f93325b0f7533f8cf: {
//   //   key: "bc6b5938a137418f93325b0f7533f8cf",
//   //   name: "APTA Members DBE",
//   //   alias: "APTAMembersDBE",
//   //   visible: false,
//   // },
//   "1103513fbc3c4a61a9e8545e36a676f7": {
//     key: "1103513fbc3c4a61a9e8545e36a676f7",
//     name: "APTA Members",
//     alias: "APTAMembers",
//     visible: false,
//   },
//   "873856ab3dfd46709ece0324d1b9756e": {
//     key: "873856ab3dfd46709ece0324d1b9756e",
//     name: "Rural Public Transit Providers",
//     alias: "RuralProviders",
//     visible: false,
//   },
//   "3e3e351a8bbd4a888b4421bb7156b0d2": {
//     key: "3e3e351a8bbd4a888b4421bb7156b0d2",
//     name: "Bus Manufacturers",
//     alias: "BusManu",
//     visible: false,
//   },
//   "8b611df1549d4731b5a1c9445496ffb5": {
//     key: "8b611df1549d4731b5a1c9445496ffb5",
//     name: "Rail Manufacturers",
//     alias: "RailManu",
//     visible: false,
//   },
//   "08539e584365402b91fbafa2ad1d7478": {
//     key: "08539e584365402b91fbafa2ad1d7478",
//     name: "Bus Station Areas",
//     alias: "BusStationAreas",
//     visible: false,
//   },
//   "05f53bd354c74667a83c032aa47caf3f": {
//     key: "05f53bd354c74667a83c032aa47caf3f",
//     name: "Amtrak Stations",
//     alias: "AmtrakStations",
//     visible: false,
//   },
//   "8729a58d4b864a01a1c8786682ea3396": {
//     key: "8729a58d4b864a01a1c8786682ea3396",
//     name: "Amtrak Routes",
//     alias: "AmtrakRoutes",
//     visible: false,
//   },
//   "8fb9b926ee2d45f5bb1cef9e17febb7a": {
//     key: "8fb9b926ee2d45f5bb1cef9e17febb7a",
//     name: "Rail Stations",
//     alias: "RailStations",
//     visible: false,
//   },
//   e9201298d352409c9e441d2909fda5aa: {
//     key: "e9201298d352409c9e441d2909fda5aa",
//     name: "Rail Lines",
//     alias: "RailLines",
//     visible: false,
//   },
//   fee8734e57894ac4a4cd2891f52f8c1d: {
//     key: "fee8734e57894ac4a4cd2891f52f8c1d",
//     name: "Transit Agency",
//     alias: "TransitAgency",
//     visible: false,
//   },
//   b51b46dfdee346939d0a30d1a42106d0: {
//     key: "b51b46dfdee346939d0a30d1a42106d0",
//     name: "Transit Agency Service Area",
//     alias: "TransitAgencyServiceArea",
//     visible: false,
//   },
//   "74cdeab461314411b6b510b58c80561e": {
//     key: "74cdeab461314411b6b510b58c80561e",
//     name: "Grant Data",
//     alias: "GrantData",
//     visible: false,
//   },
//   "5626ad6a735d4f798964afd0159c4ec5": {
//     key: "5626ad6a735d4f798964afd0159c4ec5",
//     name: "Urbanized Area",
//     alias: "UrbanizedArea",
//     visible: false,
//   },
//   "1d843651380f417a98328b112ddcbb33": {
//     key: "1d843651380f417a98328b112ddcbb33",
//     name: "States",
//     alias: "STATES",
//     visible: false,
//   },
//   "433601145e74eb0a3da5f015f11538d": {
//     key: "433601145e74eb0a3da5f015f11538d",
//     name: "House",
//     alias: "HOUSE",
//     visible: false,
//   },
//   aec2536bfd9a4752a590eaba9450dc04: {
//     key: "aec2536bfd9a4752a590eaba9450dc04",
//     name: "SLDL",
//     alias: "SLDL",
//     visible: false,
//   },
//   "8309bc28d894474ba53017d8536f93b8": {
//     key: "8309bc28d894474ba53017d8536f93b8",
//     name: "SLDU",
//     alias: "SLDU",
//     visible: false,
//   },
// };
exports.layerIdObj = {
    "1dc7bac1e4d34f19a0556d9ae048b443": {
        key: "1dc7bac1e4d34f19a0556d9ae048b443",
        name: "Public Transit Opportunity Index",
        alias: "PublicTransitOpportunityIndex",
        visible: false,
    },
    "39addb17658642a29c1f302c37cc8c91": {
        key: "39addb17658642a29c1f302c37cc8c91",
        name: "APTA Members DBE",
        alias: "APTAMembersDBE",
        visible: false,
    },
    "b0db8428a95749148d13e5a2cc126757": {
        key: "b0db8428a95749148d13e5a2cc126757",
        name: "APTA Members",
        alias: "APTAMembers",
        visible: false,
    },
    "873856ab3dfd46709ece0324d1b9756e": {
        key: "873856ab3dfd46709ece0324d1b9756e",
        name: "Rural Public Transit Providers",
        alias: "RuralProviders",
        visible: false,
    },
    "4e3cd822032746f4b2aea97f953920bb": {
        key: "4e3cd822032746f4b2aea97f953920bb",
        name: "Bus Manufacturers",
        alias: "BusManu",
        visible: false,
    },
    "4d7f27b701d54d8189a287142e403f1c": {
        key: "4d7f27b701d54d8189a287142e403f1c",
        name: "Rail Manufacturers",
        alias: "RailManu",
        visible: false,
    },
    "7d40e55519f0481c97c08715821634ff": {
        key: "7d40e55519f0481c97c08715821634ff",
        name: "Bus Station Areas",
        alias: "BusStationAreas",
        visible: false,
    },
    "7922f325c0f74cb49c2b7c35efc27404": {
        key: "7922f325c0f74cb49c2b7c35efc27404",
        name: "Amtrak Stations",
        alias: "AmtrakStations",
        visible: false,
    },
    "882ec8117fcb42059a4b3c71f1a1af30": {
        key: "882ec8117fcb42059a4b3c71f1a1af30",
        name: "Amtrak Routes",
        alias: "AmtrakRoutes",
        visible: false,
    },
    "eac94ea9c6d94b479b31b9b000b64a50": {
        key: "eac94ea9c6d94b479b31b9b000b64a50",
        name: "Rail Stations",
        alias: "RailStations",
        visible: false,
    },
    "320ff6dfb0414bc9b8d6cc2a651159e3": {
        key: "320ff6dfb0414bc9b8d6cc2a651159e3",
        name: "Rail Lines",
        alias: "RailLines",
        visible: false,
    },
    "2669bb0add9d41ebb17b8d40a4c0aaca": {
        key: "2669bb0add9d41ebb17b8d40a4c0aaca",
        name: "Transit Agency",
        alias: "TransitAgency",
        visible: false,
    },
    "b107750b74d04915ad0a38770419b10b": {
        key: "b107750b74d04915ad0a38770419b10b",
        name: "Transit Agency Service Area",
        alias: "TransitAgencyServiceArea",
        visible: false,
    },
    "3bf8d73d690446d7a10199278fce110e": {
        key: "3bf8d73d690446d7a10199278fce110e",
        name: "Grant Data",
        alias: "GrantData",
        visible: false,
    },
    "5626ad6a735d4f798964afd0159c4ec5": {
        key: "5626ad6a735d4f798964afd0159c4ec5",
        name: "Urbanized Area",
        alias: "UrbanizedArea",
        visible: false,
    },
    "234086b4e9464ec0b8aab08a82bb2c3e": {
        key: "234086b4e9464ec0b8aab08a82bb2c3e",
        name: "States",
        alias: "STATES",
        visible: false,
    },
    "ff1c8f810d2149bd8fbc7ddb9b901baa": {
        key: "ff1c8f810d2149bd8fbc7ddb9b901baa",
        name: "House",
        alias: "HOUSE",
        visible: false,
    },
    "3126c7c26c7244fb8269cc6d1083055e": {
        key: "3126c7c26c7244fb8269cc6d1083055e",
        name: "SLDL",
        alias: "SLDL",
        visible: false,
    },
    "99a916b7cfdb4014a4fb4969a809a4e2": {
        key: "99a916b7cfdb4014a4fb4969a809a4e2",
        name: "SLDU",
        alias: "SLDU",
        visible: false,
    },
};
function genLayerIdObj() {
    const layerIdObj = {};
    const keys = Object.keys(exports.layerObj);
    keys.forEach((key) => {
        //@ts-ignore
        const value = exports.layerObj[key];
        const layerId = value?.layerId;
        if (layerId) {
            //@ts-ignore
            layerIdObj[layerId] = {
                key: layerId,
                name: value.name,
                alias: value.key,
                visible: value.visible,
            };
        }
    });
}
