import * as React from "react";
import { T_cartColumn } from "../../Stores/newCartStore.ts/newCartStore";

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useStore } from "../../Hooks";
import { getColumn } from "./Config";
import { observer } from "mobx-react";
import { PRINT_STATUS_TYPES } from "APTAPackage";

export const Table: React.FC = () => {
  const newCartStore = useStore("newCartStore");
  const activePage = newCartStore?.cartPage;
  const cart = newCartStore?.cart;
  const printType = newCartStore.printType;
  const status = newCartStore?.status;

  const cartState = newCartStore.currentCartState;

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // const parsedColumns = React.useMemo(() => {
  //   const cartArray: T_cartColumn[] = [];

  //   const cartKeys = Object.keys(cart);
  //   cartKeys.forEach((key) => {
  //     let cartElem = cart?.[key];
  //     if (!cartElem) return;
  //     if (!cartElem.tempRemove) {
  //       cartArray.push(cartElem);
  //     } else if (cartElem.tempRemove && activePage == 0) {
  //       cartArray.push(cartElem);
  //     }
  //   });

  //   return cartArray;
  // }, [activePage, cart, printType]);

  // Feb 25, 2025
  // Adding a new Cart Data function that makes proper use
  // of React Table + MobX.
  const cartData = React.useMemo(() => {
    const cartArray: T_cartColumn[] = [];

    const cartKeys = Object.keys(cartState);
    cartKeys.forEach((key) => {
      let cartElem = cartState?.[key];
      if (!cartElem) return;
      if (!cartElem.tempRemove) {
        cartArray.push(cartElem);
      } else if (cartElem.tempRemove && activePage == 0) {
        cartArray.push(cartElem);
      }
    });

    return cartArray;
  }, [activePage, cartState, status, printType]);

  const table = useReactTable({
    data: cartData,
    //@ts-ignore
    columns: getColumn(activePage, newCartStore),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    autoResetPageIndex: false, // turn off page index reset when sorting or filtering
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="">
            <table
              className="table table-hover"
              style={{
                border: "none",
                tableLayout: "fixed",
                overflow: "visible",
              }}
            >
              <thead
                style={{
                  backgroundColor: "white",
                  borderRight: "none",
                  borderLeft: "none",
                }}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <td
                          className="cart-table-header-size p-md-2 p-sm-0 p-2"
                          key={header.id}
                          style={{
                            textOverflow: "ellipsis",
                            wordWrap: "normal",
                            fontWeight: 600,
                            borderRight: "none",
                            borderLeft: "none",
                            borderTop: "none",
                            overflow: "visible",
                            maxWidth: header.column.columnDef.size,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody
                style={{
                  borderBottom: "solid 1px #dee2e6",
                }}
              >
                {table.getRowModel().rows.map((row) => {
                  const isToRemove = row?.original?.tempRemove;
                  // if(isToRemove) return null;

                  return (
                    <tr
                      key={row.id}
                      style={
                        isToRemove
                          ? {
                              backgroundColor: "red",
                              textDecoration: "line-through",
                            }
                          : {}
                      }
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="cart-body-header-size p-sm-0 p-md-2 p-2"
                          style={{
                            padding: ".75rem !important",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            fontWeight: 300,
                            overflow: "visible",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {table.getPageCount() > 1 && (
            <div className="flex items-center gap-2 text-center">
              <button
                className="border rounded p-1 mr-2"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </button>
              <button
                className="border rounded p-1 mr-2"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </button>
              <span className="flex items-center gap-1  mr-2">
                Page
                <strong className="ml-1">
                  {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()}
                </strong>
              </span>
              <button
                className="border rounded p-1 mr-2"
                onClick={table.nextPage}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
              <button
                className="border rounded p-1 mr-2"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(Table);
