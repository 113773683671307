import React, { useRef, useEffect, useState } from "react";
import { LegislatorTypes, FedLeg, StateLeg, layerIdObj } from "APTAPackage";
import Legend from "./Legend";

//@ts-ignore
import { T_layerObj } from "APTAPackage/dist/v2/Layers/Types";

import { SectionTitle } from "../../../Explore/Components/SectionTitle";
import { webmapId } from "../../../../constants/configTS";
import { T_esriObj } from "../../../../Hooks/useEsriModules";
import { ArcGISIdentityManager } from "@esri/arcgis-rest-request";
import { isAlaska } from "./Utils";
import { unwatchFile } from "fs";

interface MapProps {
  esriToken: ArcGISIdentityManager | null;
  esriObj: T_esriObj | null;
  geometry: any;
  layers: T_layerObj;
  legislatorType: LegislatorTypes;
  member: FedLeg | StateLeg;
}

const Map: React.FC<MapProps> = ({
  esriObj,
  esriToken,
  geometry,
  layers,
  member,
}) => {
  const mapRef = useRef<any>(null);

  const [dataURL, setDataURL] = useState("");
  const [legendDone, setLegendDone] = useState(false);
  const [theLegend, setTheLegend] = useState<any>(undefined);

  useEffect(() => {
    if (esriToken != null && esriObj != null) {
      // TODO: Switch this to the latest version of the maps sdk.
      const {
        watchUtils,
        WebMap,
        MapView,
        Legend,
        Graphic,
        Polygon,
        IdentityManager,
      } = esriObj;

      // TODO: Switch this to token based authentication.
      IdentityManager.registerToken({
        expires: esriToken.tokenExpires,
        server: "https://www.arcgis.com/sharing/rest",
        ssl: true,
        token: esriToken.token,
      });

      let { isAlaskaZoom, isAlaskaPadding } = isAlaska(member);

      // Create a Polygon for the Legislator's Boundary
      let legislatorPolygon = new Polygon(geometry);
      const legislatorSymbol = {
        type: "simple-fill",
        color: [51, 51, 204, 0.2],
        style: "solid",
        outline: {
          color: "white",
          width: 1,
        },
      };

      const legislatorGraphic = new Graphic(
        legislatorPolygon,
        legislatorSymbol
      );

      // Init the map.
      const theWebMap = new WebMap({
        portalItem: {
          id: webmapId,
        },
        basemap: "arcgis-light-gray",
      });

      const theView = new MapView({
        map: theWebMap,
        container: mapRef.current,
        zoom: 4,
        padding: isAlaskaPadding
          ? { left: 0, top: 0, right: 0, bottom: 50 }
          : { left: 0, top: 0, right: 0, bottom: 0 },
        extent: legislatorGraphic.geometry.extent,
      });
      theView.graphics.add(legislatorGraphic);

      // Wait for the Map object to load.
      theWebMap
        .when((map: any) => {
          // Disabled all layers until after we navigate to the
          // correct area.
          map.allLayers.forEach((l: any) => {
            // TODO: Do a better job selecting the base layers
            if (
              l.title != "Light Gray Canvas Base" &&
              l.title != "Light Gray Canvas Labels"
            ) {
              l.visible = false;
            }
          });

          theView.when((view: any) => {
            let gotoArgs = { target: legislatorGraphic.geometry.extent };
            //@ts-ignore
            if (isAlaskaZoom) gotoArgs.zoom = 3;

            view.goTo(gotoArgs).then(() => {
              // view.zoom =
              const legend = new Legend({
                view: theView,
              });

              let promArr: Promise<any>[] = [];

              // Loop through and enable layers.
              map.allLayers.items.map((layer: any, index: number) => {
                const layerPromise = new Promise((resolve, reject) => {
                  const theLayerID = layer?.portalItem?.id;
                  const theLayerName = layerIdObj?.[theLayerID]?.alias;
                  // If no match for layer resolve
                  if (theLayerName == undefined) return resolve(undefined);

                  let theVisibility = layers?.[theLayerName]?.visible;
                  if (theVisibility == null || theVisibility === false)
                    return resolve(undefined);
                  map.allLayers.items[index].visible = theVisibility;
                  layer.visible = theVisibility;

                  layer
                    .when((l: any) => {
                      return view.whenLayerView(l);
                    })
                    .then((lv: any) => {
                      return watchUtils.whenFalseOnce(lv, "updating");
                    })
                    .then(() => {
                      resolve(undefined);
                    })
                    .catch((error: any) => {
                      reject(error);
                    });
                });
                promArr.push(layerPromise);
              });

              Promise.all(promArr)
                .then(() => {
                  legend.renderable.then((elem: any) => {
                    setTheLegend(legend);
                  });

                  view
                    .takeScreenshot({ ignorePadding: true })
                    .then((res: any) => {
                      if (res?.dataUrl !== undefined) {
                        setDataURL(res?.dataUrl);
                      }
                    });
                })
                .catch((error) => {
                  view.takeScreenshot().then((res: any) => {
                    if (res?.dataUrl !== undefined) {
                      setDataURL(res?.dataUrl);
                    }
                  });
                })
                .catch((error: any) => {
                  return;
                });
            });
          });
        })
        .catch((err: any) => {
          console.error(err);
          return;
        });
    }
  }, [esriObj, esriToken]);

  return (
    <>
      <div
        ref={mapRef}
        id="gm-print-view-div"
        className={`${dataURL ? "gm-invis" : null}`}
      />

      {legendDone && dataURL ? (
        <img id="DONE" className="w-100 pb-3" src={dataURL} />
      ) : null}
      <SectionTitle title="Legend" type="FULL_PAGE">
        <Legend
          esriCode={esriObj}
          legend={theLegend}
          setLegendDone={setLegendDone}
        />
      </SectionTitle>
    </>
  );
};

export default Map;
