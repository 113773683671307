import { PROD_BASE_URL } from "./../../constants/configTS";
import {
  FedLeg,
  layerObj,
  PRINT_JOB_STATUS_TYPES,
  PRINT_STATUS_TYPES,
  sectionObj,
  StateLeg,
  T_configObj,
} from "APTAPackage";
import AuthStore from "../newAuthStore/AuthStore";
import axios from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import {
  API_URLS,
  DEV_BASE_URL,
  STAGING_BASE_URL,
} from "../../constants/configTS";
import { processString } from "./parsers";
import { sendGAEventPerson } from "../../Hooks/useGoogleAnalytics";
import { stateFullToAbbrev } from "../../constants";

export type T_configType = "layers" | "sections" | "fileOptions";

export type T_cartColumn = {
  status: PRINT_STATUS_TYPES;
  type: string;
  name: string;
  state: string;
  district: string;
  chamber: string;
  hashId: string;
  postcardPreview: any;
  printPreview: any;
  pdfUrl?: string;
  tempRemove: boolean;
};

export type T_jobStatus = {
  status: PRINT_JOB_STATUS_TYPES;
  statusObj: {
    [key: string]: { status: PRINT_STATUS_TYPES; url?: string };
  };
  counts: {
    total: number;
    successCount: number;
    errorCount: number;
    percent: number;
  };
};

export type T_printType = "POSTCARD" | "PAGE";
export type T_status =
  | "INIT"
  | "LOADING"
  | "SUCCESS"
  | "ERROR"
  | "ZIP-LOADING"
  | "ZIP-ERROR";

class NewCartStore {
  authStoreRef: AuthStore | undefined;

  cartPage: number;
  cart: { [key: string]: T_cartColumn };
  printType: T_printType;
  printStatus: T_status;
  requestId: string | undefined;
  status: T_jobStatus | undefined;

  layerConfig: T_configObj;
  sectionConfig: T_configObj;
  fileOptionsConfig: { [key: string]: any }[];

  initJobStatus: T_status;
  zipUrl: string | undefined;

  constructor(authStoreRef: AuthStore | undefined) {
    this.printType = "PAGE";
    this.cartPage = 0;
    this.cart = {};
    this.printStatus = "INIT";
    this.authStoreRef = authStoreRef;
    this.requestId = undefined;
    this.status = undefined;

    this.layerConfig = layerObj;
    this.fileOptionsConfig = [
      { key: "0", name: "Combined PDF", visible: true },
      { key: "1", name: "Two PDFs", visible: false },
      { key: "2", name: "Two PNGs", visible: false },
    ];

    this.sectionConfig = sectionObj;

    this.initJobStatus = "INIT";
    this.zipUrl = undefined;

    makeObservable(this, {
      zipUrl: observable,
      initJobStatus: observable,
      cart: observable.shallow,
      printType: observable,
      setPrintType: action,
      cartPage: observable,
      printStatus: observable,
      nextPage: action,
      previousPage: action,
      getCartItem: action,
      GET_jobStatus: action,
      status: observable,
      clearCart: action,
      cartSize: computed,

      addToCart: action,
      removeFromCart: action,

      layerConfig: observable,
      sectionConfig: observable,
      fileOptionsConfig: observable,
      updateConfig: action,
      clearConfig: action,
      setStatus: action,
      GET_zip: action,
      setSectionConfig: action,
      setLayerConfig: action,

      currentCartState: computed,
    });
  }

  getItemFull(hashId: string) {
    const item = this.getCartItem(hashId);
    const status = this.status?.statusObj?.[hashId]?.status;
    return { item, status };
  }

  get currentCartState() {
    const _cart = this?.cart;
    const _status = this?.status;

    let cart: { [key: string]: T_cartColumn } | undefined = undefined;
    let status: T_jobStatus | undefined = undefined;

    // Cart Null Case
    if (_cart == null) return {};
    else cart = { ..._cart };

    if (_status != null) status = { ..._status };

    const cartKeys = Object.keys(cart);
    cartKeys.forEach((key) => {

    
      const cartItem = { ...cart[key] };

      const statusItem = status?.statusObj?.[key];

      if (statusItem != null) {
        cartItem.status =
          statusItem?.status == null
            ? PRINT_STATUS_TYPES.PRINT_INIT
            : statusItem.status;
        cartItem.pdfUrl = statusItem?.url;
      }
      cart[key] = cartItem;
    });
    return cart;
  }

  setStatus(newValue: T_jobStatus | undefined) {

    if (newValue != undefined) newValue = { ...newValue };
    this.status = newValue;
  }

  get cartSize() {
    const keys = Object.keys(this.cart);
    return keys.length;
  }

  getCartItem(hashId: string): T_cartColumn | undefined {
    const elem = this.cart[hashId];
    if (elem) return elem;
    return undefined;
  }

  nextPage() {
    if (this.cartPage == 2) {
      this.nextPagePrint();
      return;
    }

    this.cartPage++;
  }

  async nextPagePrint() {
    try {
      await this.POST_job();
      this.cartPage++;
    } catch (error) {
      return;
    }
  }

  previousPage() {
    if (this.cartPage > 0) {
      this.cartPage--;
    }
  }

  clearCart() {
    this.cartPage = 0;
    this.cart = {};
    this.setStatus(undefined);
    this.requestId = undefined;
    this.printStatus = "INIT";
    this.zipUrl = undefined;
  }

  setPrintType(type: T_printType) {
    if (this.printType !== type) this.printType = type;
  }

  addToCart(legislators: (FedLeg | StateLeg)[]) {
    if (!this.authStoreRef?.isAuth) this.clearCart();

    const cart = { ...this.cart };

    legislators.forEach((leg: FedLeg | StateLeg) => {
      const hashId = leg?.identityInfo?.hashID;
      if (!hashId) return;
      if (cart[hashId] === undefined) {
        let chamber = processString(leg?.identityInfo?.chamber);
        chamber = chamber.charAt(0).toUpperCase() + chamber.slice(1);

        let district = processString(leg?.identityInfo?.district);
        district =
          district === "00" || district == "98" ? "At Large" : district;

        let column: T_cartColumn = {
          status: PRINT_STATUS_TYPES.PRINT_INIT,

          type: processString(leg?.identityInfo?.type),
          name: processString(leg?.fullName),
          state: processString(leg?.identityInfo?.stateFull),
          district,
          chamber,
          hashId,
          postcardPreview: () => this.genPostcardPreviewUrl(hashId),
          printPreview: () => this.genPrintPreviewUrl(hashId),
          pdfUrl: undefined,
          tempRemove: false,
        };

        cart[hashId] = column;
      }
    });

    this.cart = cart;
  }

  removeFromCart(legislators: (FedLeg | StateLeg | string)[]) {
    let cart = { ...this.cart };

    legislators.forEach((leg: FedLeg | StateLeg | string) => {
      let hashId = undefined;
      if (typeof leg == "string") hashId = leg;
      else {
        hashId = leg?.identityInfo?.hashID;
      }
      if (!hashId) return;
      delete cart[hashId];
    });

    this.cart = cart;
  }

  genPostcardPreviewUrl = (hashId: string) => {
    // const baseRoute = 'https://apta2.geomarvel.com'

    let baseRoute = PROD_BASE_URL;

    if (process.env.REACT_APP_ENV === "development") {
      baseRoute = DEV_BASE_URL;
    }

    if (process.env.REACT_APP_ENV === "staging") {
      baseRoute = STAGING_BASE_URL;
    }

    return `${baseRoute}/print/postcard?hashId=${hashId}`;
  };

  genPrintPreviewUrl = (hashId: string) => {
    // const baseRoute = 'https://apta2.geomarvel.com'

    // const baseRoute =
    //   process.env.REACT_APP_ENV === "development"
    //     ? DEV_BASE_URL
    //     : PROD_BASE_URL;

    let baseRoute = PROD_BASE_URL;

    if (process.env.REACT_APP_ENV === "development") {
      baseRoute = DEV_BASE_URL;
    }

    if (process.env.REACT_APP_ENV === "staging") {
      baseRoute = STAGING_BASE_URL;
    }

    const sections = encodeURIComponent(JSON.stringify(this.sectionConfig));
    const layers = encodeURIComponent(JSON.stringify(this.layerConfig));

    return `${baseRoute}/print/full/?hashId=${hashId}&layers=${layers}&sections=${sections}`;
  };

  getConfig = (type: T_configType) => {
    if (type === "layers") return this.layerConfig;
    else if (type === "fileOptions") {
      return this.fileOptionsConfig;
    } else return this.sectionConfig;
  };

  setSectionConfig(newConfig: any) {
    this.sectionConfig = newConfig;
  }
  setLayerConfig(newConfig: any) {
    this.layerConfig = newConfig;
  }

  setFileOptionsConfig(newConfig: any) {
    if (!newConfig[1].visible && !newConfig[2].visible) {
      newConfig[0].visible = true;
    }
    this.fileOptionsConfig = newConfig;
  }

  updateConfig = (type: T_configType, key: string) => {
    const config = this.getConfig(type);
    let newConfig = { ...config };
    if (!newConfig) return;
    //@ts-ignore
    const newItem = newConfig?.[key];
    if (!newItem) return;

    newItem.visible = !newItem.visible;
    //@ts-ignore
    newConfig[key] = newItem;
    if (type === "layers") {
      //@ts-ignore
      this.layerConfig = newConfig;
    } else if (type === "fileOptions") {
      this.setFileOptionsConfig(newConfig);
    } else {
      this.setSectionConfig(newConfig);
    }
  };

  clearConfig = (type: T_configType) => {
    const config = this.getConfig(type);
    let newConfig = { ...config };
    if (!newConfig) return;

    const keys = Object.keys(newConfig);
    keys.forEach((key) => {
      //@ts-ignore
      const newItem = newConfig?.[key];
      if (!newItem) return;
      newItem.visible = false;
      //@ts-ignore
      newConfig[key] = newItem;
    });

    if (type === "layers") {
      this.setLayerConfig(newConfig);
    } else if (type === "fileOptions") {
      this.setFileOptionsConfig(newConfig);
    } else {
      this.setSectionConfig(newConfig);
    }
  };

  resetConfig = (type: T_configType) => {
    const config = this.getConfig(type);
    let newConfig = { ...config };
    if (!newConfig) return;
    const keys = Object.keys(newConfig);
    keys.forEach((key) => {
      //@ts-ignore
      const newItem = newConfig?.[key];
      if (!newItem) return;
      newItem.visible = true;
      if (
        key === "PublicTransitOpportunityIndex" ||
        key === "TransitAgencyServiceArea" ||
        key === "GrantData" ||
        key === "1" ||
        key === "2" ||
        key === "STATES" || 
        key === "HOUSE" ||
        key === "SLDU" ||
        key === "SLDL"
      )
        newItem.visible = false;

      //@ts-ignore
      newConfig[key] = newItem;
    });

    if (type === "layers") {
      this.setLayerConfig(newConfig);
    } else if (type === "fileOptions") {
      this.setFileOptionsConfig(newConfig);
    } else {
      this.setSectionConfig(newConfig);
    }
  };

  POST_job() {
    return new Promise(async (resolve, reject) => {
      try {
        const token = this.authStoreRef?.getUserStorage();

        this.printStatus = "LOADING";

        const legislators: any[] = [];
        const cartKeys = Object.keys(this.cart);

        cartKeys.forEach((key) => {
          legislators.push(this.cart[key]);
        });

        const sectionsString = encodeURIComponent(
          JSON.stringify(this.sectionConfig)
        );

        const fileOptionString = encodeURIComponent(
          JSON.stringify(this.fileOptionsConfig)
        );

        const layersString = encodeURIComponent(
          JSON.stringify(this.layerConfig)
        );

        const { data } = await axios({
          method: "post",
          url: API_URLS?.jobRoute,
          data: {
            type: this.printType,
            legislators,
            sections: this.sectionConfig,
            layers: this.layerConfig,
            fileOptions: this.fileOptionsConfig,
            sectionsString,
            fileOptionString,
            layersString,
          },
          headers: {
            Authorization: token != null ? token : "UNAUTH",
          },
        });

        const requestId = data?.requestId;
        this.requestId = requestId;

        legislators?.forEach((item) => {
          const person = {
            fullName: item.name,
            identityInfo: {
              type: item.type,
              stateAbbrev: stateFullToAbbrev?.[item?.state],
              chamber: item.chamber == "N/A" ? null : item.chamber,
              district: item.district == "N/A" ? null : item.chamber,
              hashID: item.hashID,
            },
          };

          sendGAEventPerson({
            eventName: "Print Person",
            eventLabel: "print_person",
            //@ts-ignore
            person,
          });
        });

        if (!requestId) throw new Error();
        await this.GET_jobStatus();

        return resolve(requestId);

        // this.queryPrintStatus();
      } catch (error) {
        this.printStatus = "ERROR";
        reject(error);
      }
    });
  }

  GET_zip() {
    return new Promise(async (resolve, reject) => {
      try {
        const token = this.authStoreRef?.getUserStorage();
        const url = `${API_URLS.jobRoute}/${this.requestId}/zip`;

        const headers = {
          Authorization: token != null ? token : "UNAUTH",
        };

        // start the post request first.
        const startPostZipJob = await axios({ method: "post", url, headers });

        let refetchCount = 0;

        const { data, status } = await axios({
          method: "get",
          url,
          headers,
        });

        let gotStatus = status;
        let response = data;

        while (gotStatus === 204) {
          if (refetchCount >= 10) {
            throw new Error("creating zip file took too long.");
          }

          const refetchZip = await axios({
            method: "get",
            url,
            headers,
          });

          if (refetchZip.status === 200) {
            gotStatus = refetchZip?.status;
            response = refetchZip?.data;
            break;
          }

          refetchCount++;
        }

        this.printStatus = "SUCCESS";
        this.zipUrl = response;
      } catch (error) {
        this.printStatus = "ZIP-ERROR";
        this.zipUrl = undefined;
        reject(error);
      }
    });
  }

  GET_jobStatus() {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.requestId) throw new Error("400");

        const token = this.authStoreRef?.getUserStorage();

        const { data } = await axios({
          method: "get",
          url: `${API_URLS.jobRoute}/${this.requestId}/status`,
          headers: {
            Authorization: token != null ? token : "UNAUTH",
          },
        });

        this.setStatus(data);

        if (this.status?.counts.percent != 100) {
          await new Promise(async (resolve) => {
            setTimeout(() => {
              resolve("test");
            }, 5000);
          });
          resolve(data);
          await this.GET_jobStatus();
        } else {
          // this.printStatus = "SUCCESS";
          await this.GET_zip();
        }

        // this.queryPrintStatus();
      } catch (error) {
        if (this.printStatus != "ZIP-ERROR") this.printStatus = "ERROR";
        reject(error);
      }
    });
  }
}

export default NewCartStore;
