import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import NewCartStore, {
  T_cartColumn,
} from "../../Stores/newCartStore.ts/newCartStore";
import { AiOutlineClose } from "react-icons/ai";
import { PRINT_STATUS_TYPES } from "APTAPackage";

export const titles = ["Items", "Format", "Preview", "Results"];

const columnHelper = createColumnHelper<T_cartColumn>();

type T_wizardPage = {
  title: string;
  showSections: boolean;
  showLayers: boolean;
  columns?: ColumnDef<T_cartColumn, string>[];
};

export function getPageConfig(
  page: number,
  cartStore: NewCartStore
): T_wizardPage {
  const localTitles = [...titles, "Status"];

  return {
    title: localTitles[page],
    showLayers: page === 1,
    showSections: page === 1,
    columns: getColumn(page, cartStore),
  };
}

const columnBase = [
  columnHelper.accessor("type", {
    header: () => "Type",
    cell: (info) => info.renderValue(),
    size: 80,
  }),

  columnHelper.accessor("name", {
    header: "Name",
    size: 400,
  }),
  columnHelper.accessor("state", {
    header: "State",
    size: 120,
  }),

  columnHelper.accessor("district", {
    cell: (info) => {
      return info.getValue();
    },
    header: () => "District",
    size: 120,
  }),
  columnHelper.accessor("chamber", {
    cell: (info) => info.renderValue(),
    header: () => "Chamber",
    size: 75,
  }),
];

export const getColumn = (
  idx: number,
  cartStore: NewCartStore
): ColumnDef<T_cartColumn, string>[] | undefined => {
  const columnArr = [
    [
      ...columnBase,
      columnHelper.accessor("hashId", {
        cell: (info) => {
          const hashId = info.getValue();
          return (
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => cartStore.removeFromCart([hashId])}
            >
              <AiOutlineClose color="red" size="1.25rem" />
            </div>
          );
        },
        header: () => "Remove",
      }),
    ],
    undefined,
    [
      ...columnBase,
      columnHelper.accessor("hashId", {
        cell: (info) => {
          const hashId = info.getValue();
          const isPage = cartStore.printType === "PAGE";
          const item = cartStore.getCartItem(hashId);
          let url = isPage ? item?.printPreview : item?.postcardPreview;
          if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
          ) {
            return [<a>Preview not Available on Mobile</a>];
          }
          return (
            <div>
              <a href={`${url()}&preview=true`} target="_blank">
                Print Preview
              </a>
            </div>
          );
        },
        header: () => "Print Preview",
      }),
    ],
    [
      ...columnBase,
      columnHelper.accessor("hashId", {
        cell: (info) => {



          const status = info?.row?.original?.status;
          const url = info?.row?.original?.pdfUrl;

          let full = url?.replace(".pdf", "-full.pdf");
          let png1 = url?.replace(".pdf", "-1.png");
          let png2 = url?.replace(".pdf", "-2.png");
          let pdf1 = url?.replace(".pdf", "-1.pdf");
          let pdf2 = url?.replace(".pdf", "-2.pdf");

          let links: any = [];
          let dropdownMenu;
          if (cartStore.printType === "POSTCARD") {
            let count = 0;
            if (cartStore.fileOptionsConfig[0].visible) count++;
            if (cartStore.fileOptionsConfig[1].visible) count++;
            if (cartStore.fileOptionsConfig[2].visible) count++;
            if (cartStore.fileOptionsConfig[0].visible) {
              links.push(
                <>
                  <a
                    className={count > 1 ? "dropdown-item" : ""}
                    href={full}
                    target="_blank"
                  >
                    Full PDF
                  </a>
                </>
              );
            }
            if (cartStore.fileOptionsConfig[2].visible) {
              links.push(
                <>
                  <a
                    className={count > 1 ? "dropdown-item" : "mr-2"}
                    href={png1}
                    target="_blank"
                  >
                    PNG Front
                  </a>
                  <a
                    className={count > 1 ? "dropdown-item" : ""}
                    href={png2}
                    target="_blank"
                  >
                    PNG Back
                  </a>
                </>
              );
            }
            if (cartStore.fileOptionsConfig[1].visible) {
              links.push(
                <>
                  <a
                    className={count > 1 ? "dropdown-item" : "mr-2"}
                    href={pdf1}
                    target="_blank"
                  >
                    PDF Front
                  </a>
                  <a
                    className={count > 1 ? "dropdown-item" : ""}
                    href={pdf2}
                    target="_blank"
                  >
                    PDF Back
                  </a>
                </>
              );
            }

            if (count > 1) {
              dropdownMenu = (
                <div className="dropdown show">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Links
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {links}
                  </div>
                </div>
              );
            } else {
            }
          } else {
            links.push(
              <a href={url} target="_blank">
                Link
              </a>
            );
          }
          // if (status == null) status = PRINT_STATUS_TYPES.PRINT_LOADING;
          return (
            <div>
              {status === PRINT_STATUS_TYPES.PRINT_LOADING && (
                <div className="spinner" style={{ margin: 0, height: 20 }}>
                  <div
                    className="rect1"
                    style={{ backgroundColor: "#47318e4d" }}
                  ></div>
                  <div
                    className="rect2"
                    style={{ backgroundColor: "#47318e4d" }}
                  ></div>
                  <div
                    className="rect3"
                    style={{ backgroundColor: "#47318e4d" }}
                  ></div>
                </div>
                // <Oval
                //   height={20}
                //   width={20}
                //   color="#4fa94d"
                //   wrapperStyle={{}}
                //   wrapperClass=""
                //   visible={true}
                //   ariaLabel="oval-loading"
                //   secondaryColor="#4fa94d"
                //   strokeWidth={2}
                //   strokeWidthSecondary={2}
                // />
              )}

              {status === PRINT_STATUS_TYPES.PRINT_ERROR && <p> ERROR </p>}

              {url != null && (dropdownMenu || links)}
            </div>
          );
        },
        header: () => "Status",
      }),
    ],
  ];

  return columnArr?.[idx];
};
